import { resolveRef } from "../../../core/sanityAPI/client-resolvers";
import { PageMetaSchema } from "@/schema";
import Head from "next/head";
import * as React from "react";

export const WebsiteMeta = (meta: PageMetaSchema) => {
  const image = meta.seo?.cardImage as any;

  let cardImageURL = null;

  if (image) {
    cardImageURL = `https://vodniakov.site/_next/image?url=${encodeURIComponent(image.src)}&w=1200&q=75`;
  }

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="icon" type="image/png" href="/assets/favicon.png" />
      <meta charSet="utf-8" />

      <title>{meta.title}</title>

      <meta name="description" content={meta.seo?.description} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.seo?.description} />
      <meta property="og:site_name" content="Overbooked" />
      <meta property="og:url" content="https://vodniakov.site/" />
      <meta property="og:type" content="website" />
      {cardImageURL && <meta property="og:image" content={cardImageURL} />}

      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.seo?.description} />
      <meta name="twitter:domain" content="overbooked.com" />
      <meta name="twitter:url" content="https://overbooked.com/" />
      {cardImageURL && <meta name="twitter:card" content={cardImageURL} />}
      {cardImageURL && <meta name="twitter:image" content={cardImageURL} />}
      <meta http-equiv="Permissions-Policy" content="interest-cohort=()" />
      <meta http-equiv="Permissions-Policy" content="geolocation=(self)" />
    </Head>
  );
};

export default WebsiteMeta;
