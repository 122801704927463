import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FHeader%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51W226jMBB9z1eMVFVqpSUiECBxX7bdKv%2Bwj8YMxCnYyDgN7Sr%2FvuIaQ6DN7iP2zJwzZ44nWbIsYJltw58FQC4LrrkUBGJeYvS0AIh4kaf0g0CcYlkdHI6F5vGHxaTQKDQBhkKjqq5oyhNhcY1ZYR6feKT3BFa2fV99MplKReDOtu3qM6dRxEVCwN3mJXirvEYJZWkV%2FLO%2BCKWKUFmhrG%2B0oqJjGVL2lih5FJFVVwV76RSAtEBLHjXYxQ%2BYPq8KfVpcRFgS2G6fFufFshFiVQsxLkzgbve8e9m9zic6M4mnPddoxLlzAHEcm%2FK0n3vkyV5f1KvGYEVcIWskYDI9ZmJyMIWmSl%2FNpT9NaE7A9Rq9Yyl0JTgSWNvVUc93XfO9ssGYh5KnaRI5ZWiFqE%2BINc2MllbniHWDNbJID%2B3V0N%2BV7MP9aabfU2qUGLYd3NZ2J3%2Bft63zBh7v7%2BgXd%2BE03syTqhmvhoxDspfvqBqMoyoqkFzyJqWPYkMcIQX%2BIw67CScaLRSFKdX8HQdP3s9L2DQOyKhKuCBgdwcaS21FyKSiTYWG6gXgJhpY33f22rR26x6V6w86i%2Bvg%2Bimc2ohgMKRkKB4XKRdodbPq26oEA9fJ%2F3NfGqJDt%2FwUjfixqFaOeXyZ32WfELhbPQfBznmaEmZufxobkotqSZpdG0oPcOzAd16fB5GUVUOeCPW9l18vRuh%2B2oe9hpu8BGc9sR2%2BULQvfpguPi9at4RrcXKqUJgCHG6y2tvAai3XKw4P5v6zHTcvH%2Bu8ibd%2FXpwXPzOMOJ3NWpq%2F3qYDq99St7XQ%2BRK4HmN1tIwYvyt2%2FWcAQMucAD1q2XyGUmuZEfCablt3xVJlrZYp1fj7YW3fP45Q2HzXfQy2MePnO37Ag6RkrIXpJCPuMCYQppK9NeWbZWSlGGtiyDg5jsAPbprGyruiMO5v5Vz153yJ7XrbEbY3Ktna0ADd3GABPh9z%2Fgu2BFv7MQoAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var burgerStyle = 'cm7cm0j';
export var buttonText = 'cm7cm0f';
export var contactUsBtnStyle = _7a468({defaultClassName:'cm7cm0g',variantClassNames:{menuOpen:{true:'cm7cm0h',false:'cm7cm0i'}},defaultVariants:{},compoundVariants:[]});
export var headerWrapperStyle = _7a468({defaultClassName:'cm7cm00',variantClassNames:{background:{transparent:'cm7cm01',white:'cm7cm02'},menuOpen:{true:'cm7cm03'}},defaultVariants:{menuOpen:false,background:'transparent'},compoundVariants:[]});
export var linkStyle = 'cm7cm0d';
export var logoWrapperStyle = 'cm7cm0b';
export var menuContainerStyle = 'cm7cm05';
export var menuStyle = _7a468({defaultClassName:'cm7cm06',variantClassNames:{menuOpen:{true:'cm7cm07',false:'cm7cm08'},background:{transparent:'cm7cm09',white:'cm7cm0a'}},defaultVariants:{},compoundVariants:[]});
export var mobileMenuHeadWrapper = 'cm7cm0k';
export var mobilelogoWrapperStyle = 'cm7cm0c';
export var navItemWrapper = 'cm7cm04';
export var styledLogo = 'cm7cm0e';