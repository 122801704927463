/*eslint-disable*/
"use client";

import React from "react";

import { typographyStyle } from "./typography.css";

const getTag = (settings: Settings) => {
  const mode = settings?.mode;

  switch (settings?.tag) {
    case "h1":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h1 className={`${typographyStyle({ mode: mode, tag: "h1" })} ${className}`}>{children}</h1>
      );
    case "h2":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h2 className={`${typographyStyle({ mode: mode, tag: "h2" })} ${className}`}>{children}</h2>
      );
    case "h3":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h3 className={`${typographyStyle({ mode: mode, tag: "h3" })} ${className}`}>{children}</h3>
      );
    case "h4":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h4 className={`${typographyStyle({ mode: mode, tag: "h4" })} ${className}`}>{children}</h4>
      );
    case "h5":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h5 className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</h5>
      );
    case "h6":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h6 className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</h6>
      );
    case "p":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <p className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</p>
      );
    case "button":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "button" })} ${className}`}>{children}</span>
      );
    case "menuText":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "menuText" })} ${className}`}>{children}</span>
      );
    case "label":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "label" })} ${className}`}>{children}</span>
      );
    case "slideText":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "slideText" })} ${className}`}>{children}</span>
      );
    case "centeredText":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "centeredText" })} ${className}`}>{children}</span>
      );
    case "description":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "description" })} ${className}`}>{children}</span>
      );
    case "tariffPlanList":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "tariffPlanList" })} ${className}`}>{children}</span>
      );
    case "priceText":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "priceText" })} ${className}`}>{children}</span>
      );
    case "error":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "error" })} ${className}`}>{children}</span>
      );
    case "message":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "message" })} ${className}`}>{children}</span>
      );
    case "heading":
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <h2 className={`${typographyStyle({ mode: mode, tag: "heading" })} ${className}`}>{children}</h2>
      );
    default:
      return ({ children, className }: { children: React.ReactNode, className: string }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</span>
      );
  }
};

type Settings = {
  mode?: "light" | "black" | "darkGrey" | "blue" | "error" | "success";
  tag?: string;
};

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  settings?: Settings;
}

export const Typography = (props: TypographyProps) => {
  const { children, className = "", settings = {}, ...rest } = props;

  const StyledTag = getTag(settings);

  return (
    <StyledTag className={className} {...rest}>
      {children}
    </StyledTag>
  );
};

Typography.h1 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "heading" }} {...props} />;
};

Typography.h2 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h2" }} {...props} />;
};

Typography.h3 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h3" }} {...props} />;
};

Typography.h4 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h4" }} {...props} />;
};

Typography.h5 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h5" }} {...props} />;
};

Typography.h6 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h6" }} {...props} />;
};

Typography.p = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "p" }} {...props} />;
};

Typography.span = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "span" }} {...props} />;
};
