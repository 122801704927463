import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FFooter%2Ffooter.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41UXW%2BjMBB8z69YKTrp7oHIBsKH83JJm%2FyNE8EO%2BEpsBKalPfW%2Fn2ygtd20qnhid3Y8Ox7Y%2FMEhxTGVCP6tAM5yDHr%2BwkVF4Cw7yrrgLMfdCuCJU1UTwAj90K8141WtCOAsb02f8r5timcCl4aZQtHwSgRcsWtPoGRCsU6X%2Fw694pfnoJRCMaHslp4M%2Brrj4oEA2hk5RoKSLQHcjtDLhlNYH%2FPj%2FnhnAEX5UHVyEJTA%2BrQ%2FHU73utwWlHJRBVw0XDAC21iLfF1tlm2x2dbb6VqMwVKKcO6NhB9HrG5kup4JVj92%2B0IK5vS3bn8SHixezvvoc9sRonCy%2FAsrPzG%2FKtqJwzK3KygfegJ5bpcXiZ7FeJ%2Bmp1CXy6HrZUeglXxit5chtXxk3ZQoexylSXi%2F97BFqfgjuwFOtoe7gwNODOoihQqe5gCmCDmQ1EBK2Whxa4RMjsxEz18YgXDe3iGJJ5jx%2FC3Z%2FhVnDnOW6scjx%2BF3yWOPPHfSlc0iF3iUTGH8fWWUF%2FDTTioKo3b8ZcY37sfsJnoOuRWmaD7l%2FZxiUFJXXm0yPJOZ8CTzjIOIZoQXbw8V%2B6gl3Q4qn1GL7MVS3ww9dtOQNEk%2F9cNZHfD2xi6LSvMzorxjpeJSEH3vw1Xs3p2I0Zcysij%2FIOMbu4VvpP8BNAlkeZoFAAA%3D%22%7D"
export var buttonText = '_12d14do6';
export var contactUsBtnStyle = '_12d14do5';
export var contentContainer = '_12d14do1';
export var copyright = '_12d14do8';
export var footerContainer = '_12d14do0';
export var hideDesctop = '_12d14do4';
export var hideMobile = '_12d14do3';
export var menuText = '_12d14do7';
export var navWrapper = '_12d14do2';
export var styledLogo = '_12d14do9';