import { FC } from "react";
import Link from "next/link";
import { Typography } from "@/uikit/Typography";
import { isSameLinkOrParentSlug, isTextLink } from "../../../../core/utils";
import * as styles from '../header.css'
import { handleMenuLinkClick } from "../hooks";
import { HeaderSchema } from "@/schema";

interface NavigationMenuProps {
    data: HeaderSchema;
    closeMenu: () => void;
    pathname: string | null;
    menuOpen: boolean;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({ data, closeMenu, pathname, menuOpen }) => (
    <nav className={styles.menuStyle({ menuOpen })}>
        {data.navMenuItems?.map((item, index) => {
            if (isTextLink(item)) {
                const href = item?.link?.href || "/";
                const active = isSameLinkOrParentSlug(pathname ?? '', href);
                return (
                    <Link href={href} key={index} className={styles.linkStyle} onClick={(event) => handleMenuLinkClick(event, href, closeMenu)}>
                        <Typography settings={{ tag: "menuText", mode: 'black' }}>{item.text}</Typography>
                    </Link>
                );
            }
            return null;
        })}
    </nav>
);