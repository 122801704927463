import { StyleRule } from "@vanilla-extract/css";
import { breakpoints } from "./variables";

const vw = (px: number, breakpoint: number) => `${(px / breakpoint) * 100}vw`;

export const vwLg = (px: number) => vw(px, breakpoints.lg);
export const vwMd = (px: number) => vw(px, breakpoints.md);
export const vwSm = (px: number) => vw(px, breakpoints.sm);

type MediaConfig = Record<keyof typeof breakpoints, StyleRule>;

const media = (breakpoint: number) => {
  return (args: StyleRule): { [query: string]: StyleRule } => {
    return {
      [`(max-width: ${breakpoint - 1}px)`]: args,
    };
  };
};
export function mediaMap(config: Partial<MediaConfig>) {
  let result = {};
  for (const [key, value] of Object.entries(config)) {
    result = {
      ...result,
      [`(max-width: ${breakpoints[key as keyof typeof breakpoints] - 1}px)`]:
        value,
    };
  }

  return result;
}

export const mediaLg = media(breakpoints.lg);
export const mediaMd: (args: StyleRule) => { [query: string]: StyleRule } =
  media(breakpoints.md);
export const mediaSm = media(breakpoints.sm);
export const mediaXs = media(breakpoints.xs);
export const mediaSmall = media(breakpoints.small);
export const mediaTablet = media(breakpoints.tablet);
export const mediaColumn = media(breakpoints.column);
export const mediaHeaderMobile = media(breakpoints.mobileHeader);
export const mediaDemo = media(breakpoints.demo);
export const mediaXl = media(breakpoints.xl);
export const mediaXxl = media(breakpoints.xl);

export const transition = (prop: any[]) => {
  const params = "0.2s ease-out";
  return prop.map
    ? `${prop.map((p) => `${p} ${params}`).join(",")}`
    : `${prop} ${params}`;
};

export function clsx(...args: Array<string | undefined>) {
  return args.filter(Boolean).join(" ");
}
