import { MouseEvent } from "react";
export const handleMenuLinkClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  href: string,
  closeMenu: () => void
) => {
  event.preventDefault();
  closeMenu();

  const targetId = href.substring(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    const yOffset = -80;
    const y =
      targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  } else {
    window.location.href = href;
  }
};

export const handleButtonClick = (
  event: React.MouseEvent<HTMLButtonElement>,
  href: string,
  closeMenu: () => void
) => {
  event.preventDefault();
  closeMenu();

  const targetId = href.substring(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    const yOffset = -80;
    const y =
      targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  } else {
    window.location.href = href;
  }
};
