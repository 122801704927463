'use client';
import { resolveRef } from "../../core/sanityAPI/client-resolvers";
import { AppProps } from "next/app";
import { PageData } from "../../core/sanityAPI/fetchers";
import { PageLikeObject } from "../../core/sanityAPI/fetchers/base";
import { FooterSchema, HeaderSchema, PageMetaSchema } from "@/schema";
import { Header } from "../components/Header";
import { WebsiteMeta } from "@/components/Meta/WebsiteMeta";
import { Figtree } from "next/font/google";
import "../styles/global.css";
import { Footer } from "@/components/Footer";

const figtree = Figtree({ subsets: ["latin"] });

function getFinalMeta(page?: PageLikeObject): PageMetaSchema | undefined {
    if (!page) {
        return undefined;
    }

    let title = page.meta?.title;
    let pageCard = page.meta?.seo?.cardImage;

    return {
        _type: page.meta?._type as "pageMeta" ?? "",
        title: title,
        seo: {
            _type: page.meta?.seo?._type as "pageSeo" ?? "",
            description: page.meta?.seo?.description,
            cardImage: pageCard,
        },
    };
}

export default function App({ Component, pageProps }: AppProps<PageData>) {
    const header = resolveRef(pageProps?.site?.header);
    const footer = resolveRef(pageProps.site?.footer);

    const headerData = header as unknown as HeaderSchema;
    const footerData = footer as unknown as FooterSchema;
    const meta = getFinalMeta(pageProps.body);

    return (
        <div id="wrapper" className={figtree.className}>
            {headerData && <Header data={headerData} />}

            <Component {...pageProps} />

            {meta && <WebsiteMeta {...meta} />}

            <Footer  {...footerData} />

        </div>
    );
}