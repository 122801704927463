import { FC } from "react";
import { FlexContainer } from "@/uikit/container";
import Link from "next/link";
import { Typography } from "@/uikit/Typography";
import { isSameLinkOrParentSlug, isTextLink } from "../../../../core/utils";
import IconBurger from '../../../svg/icons/burger.svg';
import IconCross from '../../../svg/icons/IconCross.svg';
import IconLogo from '../../../svg/icons/logo.svg';
import * as styles from '../header.css';
import { mobileButtoContainer, mobileItemWrapper, mobileLinkStyle, mobileMenu, mobileMenuContactUsBtnStyle } from './styles.css'
import { handleButtonClick, handleMenuLinkClick } from "../hooks";
import { HeaderSchema, ButtonSchema } from "@/schema";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { usePathname } from "next/navigation";

interface MobileMenuProps {
    data: HeaderSchema;
    menuOpen: boolean;
    setMenuOpen: (open: boolean) => void;
    button: Array<ButtonSchema> | undefined;
    closeMenu: () => void;
}

export const MobileMenu: FC<MobileMenuProps> = ({ data, menuOpen, setMenuOpen, button, closeMenu }) => {
    const pathname = usePathname();

    return (
        <div className={mobileItemWrapper}>
            <FlexContainer justifyContent="between" className={styles.mobileMenuHeadWrapper}>
                <FlexContainer className={styles.menuContainerStyle}>
                    <Link href="/" className={styles.mobilelogoWrapperStyle}>
                        <IconLogo className={styles.styledLogo} />
                    </Link>
                </FlexContainer>
                <FlexContainer gap="none">
                    <ActionButtons buttons={button} menuOpen={menuOpen} closeMenu={closeMenu} />
                    <button
                        className={styles.burgerStyle}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        {menuOpen ? <IconCross /> : <IconBurger />}
                    </button>
                </FlexContainer>
            </FlexContainer>
            <FlexContainer alignItems="start" justifyContent="center" gap="50" className={mobileMenu({ menuOpen })}>
                {data.navMenuItems?.map((item, index) => {
                    if (isTextLink(item)) {
                        const href = item?.link?.href || "/";
                        const active = isSameLinkOrParentSlug(pathname ?? '', href);
                        return (
                            <Link href={href} key={index} className={mobileLinkStyle({ menuOpen })} onClick={(event) => handleMenuLinkClick(event, href, closeMenu)}>
                                <Typography settings={{ tag: "menuText", mode: 'black' }}>{item.text}</Typography>
                            </Link>
                        );
                    }
                    return null;
                })}
            </FlexContainer>
            <FlexContainer className={mobileButtoContainer}>
                {button && button.map((button, index) => (
                    <button
                        key={index}
                        className={mobileMenuContactUsBtnStyle({ menuOpen })}
                        onClick={(event) => handleButtonClick(event, button.link?.href || '/', closeMenu)}
                    >
                        <Typography settings={{ mode: "light", tag: "button" }} className={styles.buttonText}>
                            {button.text}
                        </Typography>
                    </button>
                ))}
            </FlexContainer>
        </div>
    );
};
