export const breakpoints = {
    xs: 360,
    small: 400,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
    xxl: 2860,
    demo: 1124,
    column: 1200,
    mobileHeader: 840,
    tablet: 980,
};

export const pageBreakpoints = {
    xxs: 360,
    xs: 540,
    xsN2: 768,
    sm: 1024,
    md: 1360,
    lg: 1440,
    xl: 1560,
    xxl: 1920,
};

export const Header_Height = {
    default: 0,
}

export const STAR_COUNT = 5
export const SLIDES_PER_VIEV = 3
export const SLIDES_SPACE_BETWEEN = 566