import { FC } from "react";
import { Typography } from "@/uikit/Typography";
import * as styles from '../header.css';
import { handleButtonClick } from "../hooks";
import { ButtonSchema } from "@/schema";

interface ActionButtonsProps {
    buttons: Array<ButtonSchema> | undefined;
    menuOpen: boolean;
    closeMenu: () => void;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ buttons, menuOpen, closeMenu }) => (
    <>
        {buttons && buttons.map((button, index) => (
            <button
                key={index}
                className={styles.contactUsBtnStyle({ menuOpen })}
                onClick={(event) => handleButtonClick(event, button.link?.href || '/', closeMenu)}
            >
                <Typography settings={{ mode: "light", tag: "button" }} className={styles.buttonText}>
                    {button.text}
                </Typography>
            </button>
        ))}
    </>
);
